import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c704bd1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-select" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "no-bottom-margin" }
const _hoisted_4 = { class: "is-unselectable" }
const _hoisted_5 = {
  class: "float-left",
  for: "{{ vid }}"
}
const _hoisted_6 = { class: "is-unselectable" }
const _hoisted_7 = {
  class: "float-right action",
  for: "{{ vid }}"
}
const _hoisted_8 = ["id", "name"]
const _hoisted_9 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString($setup.label), 1)
        ]),
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString($setup.action), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["control is-unselectable", { 'has-icons-left': $setup.icon }])
      }, [
        _createElementVNode("select", {
          class: _normalizeClass(["select is-fullwidth", { 'is-loading': $setup.loading }]),
          id: $setup.vid,
          name: $setup.name
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option.id,
              selected: option == ''
            }, _toDisplayString(option.name.replace(/ \[.*\]$/, '')), 9, _hoisted_9))
          }), 256))
        ], 10, _hoisted_8),
        _createElementVNode("span", {
          class: _normalizeClass(["icon is-left", { 'is-hidden': !$setup.icon }])
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fas", $setup.icon])
          }, null, 2)
        ], 2)
      ], 2)
    ])
  ]))
}