
import { ref } from "vue";
import {mapGetters, useStore} from "vuex";
import store from "@/store";

export default {
  name: "Input",
  inheritAttrs: false,
  components: {},
  setup(props, context) {
    const tag_id = context.attrs.id;
    const name = context.attrs.name;
    const type = context.attrs.type;
    const checked = context.attrs.checked;
    const value = context.attrs.value;
    const icon = context.attrs.icon;
    const placeholder = context.attrs.placeholder;
    const required = context.attrs.required;
    const min = context.attrs.min;
    const max = context.attrs.max;
    const minlength = context.attrs.minlength;
    const maxlength = context.attrs.maxlength;
    const label = context.attrs.label;
    const action = context.attrs.action;
    let class_name = ref(context.attrs.class_name);
    const pattern = context.attrs.pattern;
    const sub_component = context.attrs.sub_component;
    const store = useStore();


    if(label == "Suspected Violation of School Policy"){
			let formRecord = store.getters["form_data/getFormRecord"]
			if (!formRecord.location_type[0].includes('K-12 Public School')) {
        class_name.value = class_name.value + " is-hidden";
			}
		}
    if(tag_id == 'actions_taken_none'){
      let activePerson = store.getters["form_data/getActivePerson"]
			if (activePerson.stop_reason[0].includes('Consensual Encounter and Search')) {
        class_name.value = class_name.value + " is-hidden";
			}
    }
    
    function run_onclick() {
      if (context.attrs.onclick_func) {
        eval(context.attrs.onclick_func);
      }
    }

    function run_oninput() {
      if (context.attrs.oninput_func) {
        eval(context.attrs.oninput_func);
      }
    }    

    if (icon) {
      class_name.value = class_name.value + " indent-more";
    }
    
    return {
      tag_id,
      name,
      type,
      checked,
      value,
      icon,
      placeholder,
      required,
      min,
      max,
      minlength,
      maxlength,
      label,
      action,
      class_name,
      sub_component,
      run_onclick,
      run_oninput,
      pattern,
    };
  },
};
