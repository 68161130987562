import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17245ec4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-input" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = {
  key: 0,
  class: "no-bottom-margin"
}
const _hoisted_4 = { class: "has-text-left" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "is-1 has-text-right" }
const _hoisted_7 = ["for"]
const _hoisted_8 = { class: "control has-icons-left" }
const _hoisted_9 = ["id", "name", "type", "value", "placeholder", "required", "min", "max", "sub_component", "checked"]
const _hoisted_10 = ["for", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.type != 'checkbox' && $setup.type != 'radio')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", {
                class: _normalizeClass(["float-left", $setup.class_name]),
                for: $setup.tag_id
              }, _toDisplayString($setup.label), 11, _hoisted_5)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", {
                class: _normalizeClass(["float-right action", $setup.class_name]),
                for: $setup.tag_id
              }, _toDisplayString($setup.action), 11, _hoisted_7)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("input", _mergeProps(_ctx.$attrs, {
          id: $setup.tag_id,
          name: $setup.name,
          type: $setup.type,
          value: $setup.value,
          placeholder: $setup.placeholder,
          required: $setup.required,
          min: $setup.min,
          max: $setup.max,
          class: $setup.class_name,
          sub_component: $setup.sub_component,
          checked: $setup.checked,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.run_onclick && $setup.run_onclick(...args))),
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.run_oninput && $setup.run_oninput(...args)))
        }), null, 16, _hoisted_9),
        ($setup.type === 'checkbox' || $setup.type === 'radio')
          ? (_openBlock(), _createElementBlock("label", {
              key: 0,
              class: _normalizeClass(["after-label", $setup.class_name]),
              for: $setup.tag_id,
              id: _ctx.label_id
            }, _toDisplayString($setup.label), 11, _hoisted_10))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["icon is-left", { 'is-hidden': !$setup.icon }])
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fas", $setup.icon])
          }, null, 2)
        ], 2)
      ])
    ])
  ]))
}