
import router from "@/router"
import Input from "@/components/Input.vue"
import Select from "@/components/Select.vue"
import { mapActions, mapGetters } from "vuex"
import {onMounted, onUnmounted, ref} from "vue";
import { useStore } from 'vuex'

export default {
	name: "About",
	components: {Input, Select},
	setup() {
		const store = useStore()

		const firstName = ref(store.getters["session/getFirstName"])
		const lastName = ref(store.getters["session/getLastName"])
		const organizationName = ref(store.getters["session/getOrganizationName"])

		let errors = ref([])
		let assignmentOptions = ref(store.getters["profile/getAssignmentOptions"])
		let assignment = ref(parseInt(store.getters["profile/getAssignment"]))
		let newAssignment = assignment.value
		let experience = ref(parseInt(store.getters["profile/getExperience"]))
		let newExperience = experience.value
		let otherAssignment = ref(store.getters["profile/getOtherAssignment"])

		

		let newOtherAssignment = otherAssignment.value
		let showOtherAssignment = ref(assignment.value === 10)
		let profileString = ref(store.getters["profile/getProfileString"])
		
		

		function changeAssignment(event) {
			let index = event.target.selectedIndex

			showOtherAssignment.value = index === 10

			if (!showOtherAssignment.value) {
				otherAssignment.value = ""
			}

			newAssignment = index
			// assignment.value = index
		}

		function changeExperience(event) {
			newExperience = event.target.value
			// experience.value = event.target.value
		}

		function changeOtherAssignment(event) {
			newOtherAssignment = event.target.value
			// otherAssignment.value = event.target.value
		}

		// save assignment and experience
		function onDone() {
			let experience = document.getElementById('experience');
			
			let formTags = document.querySelectorAll("input");
			Array.from(formTags).forEach((formTag: HTMLInputElement) => {
				let fieldId = formTag.id;
				if(fieldId == 'experience'){
					newExperience = +formTag.value
				}
				
			})
			errors.value = []
			
			if (newAssignment < 1 || newAssignment > 10 || isNaN(newAssignment)) {
				errors.value.push("Select a valid option for Officer Assignment")
			}

			if (newExperience < 1 || newExperience > 50 || isNaN(newExperience)) {
				errors.value.push("Enter a valid number for Years of Experience")
			}

			if (newAssignment === 10
				&& (!newOtherAssignment
					|| (newOtherAssignment.length < 3
						|| newOtherAssignment.length > 250))) {
				errors.value.push("Enter a valid Other Assignment")
			}
			
			if (newAssignment !== 10){
				newOtherAssignment = ''
			}

			if (errors.value.length > 0) {
				return
			}
			store.dispatch("profile/setAssignment", newAssignment)
			store.dispatch("profile/setExperience", newExperience)
			store.dispatch("profile/setOtherAssignment", newOtherAssignment)
			
			router.push("/home")
		}

		function onCancel() {
			router.push("/home")
		}

		function onKeyDownEnter(event) {
			switch (event.keyCode) {
				case 13: {
					event.preventDefault()
					onDone()
				}
					break
			}
		}

		onMounted(() => {
			window.addEventListener('keydown', onKeyDownEnter)
		})

		onUnmounted(() => {
			window.removeEventListener('keydown', onKeyDownEnter)
		})

		return { firstName, lastName, profileString, organizationName,
			assignmentOptions, assignment, experience, otherAssignment, history,
			changeAssignment, changeExperience, changeOtherAssignment, showOtherAssignment,
			errors, onDone, onCancel }
	},
	computed: {
		...mapGetters("profile", ["getAssignment", "getExperience", "getOtherAssignment"])
	},
	methods: {
		...mapActions('profile', ["setAssignment", "setExperience", "setOtherAssignment"])
	}
}
