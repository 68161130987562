
import {ref} from "vue";

export default {
	name: "Select",
	components: {},
	setup(props, context) {
		const vid: string = context.attrs.vid
		const name: string = context.attrs.name
		const icon: string = context.attrs.icon
		const label: string = context.attrs.label
		const action: string = context.attrs.action
		const options: unknown = context.attrs.options
		let selected: number = context.attrs.selected
		let loading: string = ref(context.attrs.loading)

		return { vid, name, icon, label, action, options, selected, loading }
	},
	mounted() {
		let selectObject = document.getElementById(this.vid) as HTMLSelectElement
		selectObject.selectedIndex = this.selected
	}
}
